import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isMenuOpen = false;
  isDropdownOpen = false;
  openDropdown: boolean = false;

  constructor(public router: Router ){}

  toggleDropdown() {
    this.openDropdown = !this.openDropdown;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen; 
  }

  toggleDropdown1() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  
  closeMenu() {
   this.isMenuOpen = false;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}


