<div class="body">
<h1 class="m-b-20 font-bold text-center text-4xl text-slate-700 mt-96 animate__animated animate__fadeInLeftBig">Achievements</h1>

<div class="container1">
    <div *ngFor="let items of data" class="high-box1 shadow-2xl animate__animated animate__zoomIn">
        <img [src]="items.image"  alt="">
        <h2 class="mt-4 text-center text-lg"><b>{{items.head}}</b></h2>
        <p class="h-para text-justify">{{items.text}}
        </p>
    </div>
</div>
<div class="container2">
    <div *ngFor="let items2 of data2" class="high-box2 shadow-2xl animate__animated animate__zoomIn">
        <img [src]="items2.image" alt="">
        <h2 class="mt-4 text-center text-lg"><b>{{items2.head}}</b></h2>
        <p class="h-para text-justify">{{items2.text}}
        </p>
    </div>
  </div>
</div>