<footer class="px-4 pt-8 pb-8 text-white bg-white border-t border-gray-200">
  <div class="container flex flex-col justify-between max-w-6xl px-4 mx-auto overflow-hidden lg:flex-row">
    <div class="mt-16 w-full pl-12 mr-4 text-left lg:w-1/4 sm:text-left sm:pl-0 lg:text-left">
      <a href="/"
        class="flex justify-start text-left sm:text-start lg:text-left sm:justify-start lg:justify-start">
        <span class="zero flex items-start  sm:items-start ml-6">
          <svg class=" w-auto h-6 ml-[20px] text-gray-800 fill-current" viewBox="0 0 194 116" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="evenodd">
              <path d="M96.869 0L30 116h104l-9.88-17.134H59.64l47.109-81.736zM0 116h19.831L77 17.135 67.088 0z">
              </path>
              <path d="M87 68.732l9.926 17.143 29.893-51.59L174.15 116H194L126.817 0z"></path>
            </g>
          </svg>
        </span>
      </a>
        <li><a href="" class="first inline-block mt-2 px-3 py-1 font-medium text-sm  text-gray-500 no-underline hover:text-gray-600">Mega Food Park</a></li>
        <li><a href="" class="second inline-block px-3 py-1 font-medium text-sm text-gray-500 no-underline hover:text-gray-600">Agriculture Processing Cluster</a></li>
        <li><a href="" class="third inline-block px-3 py-1 font-medium text-sm text-gray-500 no-underline hover:text-gray-600">Food Consultant</a></li>
        <li><a href="" class="fourth inline-block px-3 py-1 font-medium text-sm text-gray-500 no-underline hover:text-gray-600">Mentor, Advisor, Implementor</a></li>
        <li><a href="" class="fifth inline-block px-3 py-1 font-medium text-sm text-gray-500 no-underline hover:text-gray-600">Government Scheme</a></li>
    </div>
    <div  class="block w-full pl-10 mt-6 text-sm lg:w-3/4 sm:flex lg:mt-0">
      <ul class="flex flex-col w-full p-0 font-medium text-left text-gray-700 list-none">
        <li class="inline-block px-3 py-2 mt-5 font-bold tracking-wide text-gray-800 uppercase md:mt-0">
          Contact</li>
        <li><a href="https://api.whatsapp.com/send?phone=9420496260"
            class="inline-block px-3 py-2 text-gray-500 no-underline hover:text-gray-600">9420496260</a>
        </li>
      </ul>
      <ul class="flex flex-col w-full p-0 font-medium text-left text-gray-700 list-none">
        <li class="inline-block px-3 py-2 mt-5 font-bold tracking-wide text-gray-800 uppercase md:mt-0">
          Mail</li>
        <li><a routerLink="mailto:vijaychole@gmail.com"
            class="inline-block px-3 py-2 text-gray-500 no-underline hover:text-gray-600">vijaychole@gmail.com</a>
        </li>
      </ul>
      <ul class="flex flex-col w-full p-0 font-medium text-left text-gray-700 list-none">
        <li class="inline-block px-3 py-2 mt-5 font-bold tracking-wide text-gray-800 uppercase md:mt-0">
          Key Focus
        </li>
        <li><a href="#about1" class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">About</a></li>
        <li><a href="achievement"  class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">Achievements</a></li>
        <li><a href="goals" class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">Visionary
          Approach</a></li>
        <li><a href="#events" class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">Events</a></li>
        <li><a href="media" class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">Stepping Stones</a></li>
        <li><a href="#" class="inline-block px-3 py-1 text-gray-500 no-underline hover:text-gray-600">Gallary</a></li>
      </ul>
      <div class="flex flex-col w-full text-gray-700">
        <div class="inline-block px-3 py-2 mt-5 font-bold text-gray-800 uppercase md:mt-0">Follow Us</div>
        <div class="flex justify-start pl-4 mt-2">
          <a class="flex items-center block mr-6 text-gray-400 no-underline hover:text-gray-600" target="_blank"
            rel="noopener noreferrer" href="https://www.facebook.com/vijay.chole">
            <svg viewBox="0 0 24 24" class="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z">
              </path>
            </svg>
          </a>
          <a class="flex items-center block mr-6 text-gray-400 no-underline hover:text-gray-600" target="_blank"
            rel="noopener noreferrer" href="https://x.com/vijaychole?t=7VTBHDIrQhumxWwYX9O_0w&s=09">
            <svg viewBox="0 0 24 24" class="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z">
              </path>
            </svg>
          </a>
          <a class="flex items-center block text-gray-400 no-underline hover:text-gray-600" target="_blank"
            rel="noopener noreferrer" href="https://www.linkedin.com/in/vijaykumar-chole-1a439348?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <svg viewBox="0 0 24 24" class="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-4 pt-6 mt-10 text-center text-gray-500 border-t border-gray-100">Copyright © 2024 techCognics.com - All rights reserved.</div>
</footer>