import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from '../events/events.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [HomeComponent,EventsComponent],
  imports: [
    CommonModule,
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    RouterModule.forChild([
        {
          path: '',
          component: HomeComponent,
        },
      ]),
    ],
    schemas: [
      NO_ERRORS_SCHEMA
    ],
  })
export class HomeModule { }
