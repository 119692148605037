<div class="bg-gradient-to-r from-blue-200 via-steelblue-300 to-darkblue-300">
  <div class="gap-4 ml-4 mr-4 mt-14 pb-4 pt-8 hover:text-gray-300">
        <h1 class="text-center font-semibold text-3xl font-serif animate__animated animate__fadeInLeftBig">NewsPaper Article</h1>
        <div class="grid grid-cols-2 gap-4 mb-6  mt-4 sm:grid-cols-1  lg:grid-cols-2 animate__animated animate__fadeInUp">
            <div>
               <img class="hover:scale-105 transition duration-150 ease-in-out h-full max-w-full rounded-2xl" src="../../assets/NewsPaper/Image2.jpg" alt="" />
            </div>
            <div>
                <img class="rounded-2xl mt-1 gap-y-4 mb-4 hover:scale-105 transition duration-150 ease-in-out" src="../../assets/NewsPaper/Image1.jpg" alt="" />
                <img class="h-auto max-w-full rounded-2xl gap-y-4 hover:scale-110 transition duration-150 ease-in-out" src="../../assets/NewsPaper/Image3.jpg" alt="" />
            </div>

            <div class="grid grid-rows-2 gap-4">
                <div>
                    <img class="hover:scale-105 transition duration-150 ease-in-out rounded-lg" src="../../assets/NewsPaper/Image6.jpg" alt="" />
                </div>
                <div>
                    <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-2xl" src="../../assets/NewsPaper/Image5.jpg" alt="" />
                </div>
            </div>

            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out rounded-lg" src="../../assets/NewsPaper/Image7.jpg" alt="" />
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full mt-4 rounded-lg" src="../../assets/NewsPaper/Image4.jpg" alt="" />
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-2xl mb-4" src="../../assets/NewsPaper/Image8.jpg" alt="" />
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-2xl mb-4" src="../../assets/NewsPaper/Image11.jpg" alt="" />
            </div>

            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-full max-w-full rounded-2xl mb-8" src="../../assets/NewsPaper/Image9.jpg" alt="" />
            </div>
        </div>
    </div>
</div>