import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./newspaper-gallary/newspaper-gallary.module').then((m) => m.NewspaperGallaryModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./webinars/webinars.module').then((m) => m.WebinarModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./gallary/gallary.module').then((m) => m.GallaryModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./achievements/achieve.module').then((m) => m.AchieveModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./goals/goals.module').then((m) => m.GoalsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./media/media.module').then((m) => m.MediaModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
