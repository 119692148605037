<section class="section key-feature relative " >
    <div class="container3" (click)="scrollToTop()">
      <div class="row justify-between text-center lg:text-start">
        <div class="stone-head lg:col-5 text-3xl">
          <h1 class="animate__animated animate__fadeInLeftBig"><b>Stepping stones</b></h1>
        </div>
      </div>
      <div class="stone1 key-feature-grid grid  gap-7 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3">
        <div *ngFor="let info of data3" class="flex flex-col justify-between rounded-lg bg-sky-50 p-9 shadow-lg animate__animated animate__fadeInRightBig">
          <div>
            <img [src]="info.img" class="w-[320px] h-[200px]" alt="">
            <h3 class=" text-2xl lg:text-base text-center mt-2 "><b>{{info.title}}</b></h3>
            <p class="text-sm mt-3 text-justify">{{info.content}}</p>
          </div>
        </div>
      </div>
      <div class="stone2 key-feature-grid grid gap-7 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-2">
        <div *ngFor="let info1 of data4" class="flex flex-col justify-between rounded-lg bg-sky-50 p-9 shadow-lg animate__animated animate__fadeInRight">
          <div>
            <img [src]="info1.img" class="w-[320px] h-[200px]" alt="">
            <h3 class=" text-2xl lg:text-base text-center mt-2"><b>{{info1.title}}</b></h3>
            <p class="text-sm mt-3 text-justify">{{info1.content}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
