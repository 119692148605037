<body class="bg-indigo-100 pt-4 pb-1 mt-14">

    <div class=" three gap-4 ml-4 mr-4">
        <div>
            <h1 class="text-center font-semibold text-3xl font-serif hover:text-gray-300 animate__animated animate__fadeInLeftBig">vijaykumar chole at glance</h1>
        </div>
        <div class="grid grid-cols-4 gap-4 mb-4 mt-4 animate__animated animate__zoomIn">
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out rounded-lg"
                    src="../../assets/gallery/gallery/Image1.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image5.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image3.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image12.jpg" alt="">
            </div>
        </div>
        <!--  -->
        <div class="grid grid-cols-4 gap-4 mb-4 animate__animated animate__zoomIn">
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image2.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image15.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image7.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out "
                    src="../../assets/gallery/gallery/Image20.jpg" alt="">
            </div>
        </div>
        <!--  -->
        <div class="grid grid-cols-4 gap-4 mb-4 animate__animated animate__zoomIn">
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image13.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image14.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image19.jpg" alt="">
            </div>
            <div>
                <img class="h-auto max-w-full rounded-lg hover:scale-105 transition duration-150 ease-in-out"
                    src="../../assets/gallery/gallery/Image16.jpg" alt="">
            </div>
        </div>
        <!--  -->
        <div class="grid grid-cols-4 gap-4 animate__animated animate__zoomIn">
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out one max-w-full"
                    src="../../assets/gallery/gallery/Image17.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image9.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image4.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out twenty-one h-auto max-w-full mt-1.5 rounded-xl"
                    src="../../assets/gallery/gallery/Image21.jpg" alt="">
            </div>

        </div>
        <!--  -->
        <div class="four grid grid-cols-3 gap-4 animate__animated animate__zoomIn">
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg space-y-8 -mt-24"
                    src="../../assets/gallery/gallery/Image10.jpg" alt="">

            </div>
            <div class="mt-[40px]">
                <img class="hover:scale-105 transition duration-150 ease-in-out five rounded-lg"
                    src="../../assets/gallery/gallery/Image18.jpg" alt="">
                <img class="hover:scale-105 transition duration-150 ease-in-out six h-auto max-w-full rounded-lg "
                    src="../../assets/gallery/gallery/Image11.jpg" alt="">
            </div>
            <div>
                <img class="hover:scale-105 transition duration-150 ease-in-out h-auto max-w-full rounded-lg"
                    src="../../assets/gallery/gallery/Image6.jpg" alt="">
                <img src="../../assets/gallery/gallery/Image8.jpg"
                    class="mt-9 hover:scale-105 transition duration-150 ease-in-out rounded-lg" alt="">
            </div>
        </div>
    </div>
</body>