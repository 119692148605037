import { NgModule, CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeModule } from './home/home.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AchieveModule } from './achievements/achieve.module';
import { FooterComponent } from './footer/footer.component';
import { GoalsModule } from './goals/goals.module';
import { NewspaperGallaryModule } from './newspaper-gallary/newspaper-gallary.module';
import { WebinarModule } from './webinars/webinars.module';
import { GallaryModule } from './gallary/gallary.module';
import { MediaModule } from './media/media.module';
import { ContactModule } from './contact/contact.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    
  ],
  imports: [
    CarouselModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HomeModule,
    AchieveModule,
    GoalsModule,
    NewspaperGallaryModule,
    MediaModule,
    WebinarModule,
    GallaryModule,
    ContactModule,ReactiveFormsModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
