import { Component } from '@angular/core';

@Component({
  selector: 'app-newspaper-gallary',
  templateUrl: './newspaper-gallary.component.html',
  styleUrls: ['./newspaper-gallary.component.scss']
})
export class NewspaperGallaryComponent {

}
