<div class="first bg-indigo-200">
    
    <div>
        <div class="first1 hover:text-gray-300 mt-4">
            <h1 class="text-center font-semibold text-3xl font-serif animate__animated animate__fadeInLeftBig">Seminars/Webinars</h1>
        </div>
        <div  class="grid grid-cols-2 gap-x-4 gap-5 gap-y-4 ml-6 mr-6 mt-1 sm:grid-cols-1 lg:grid-cols-2 animate__animated animate__fadeInUp">
        <img src="../../assets/Images/Image1.jpg" alt="Image 1" class="w-full h-auto rounded-2xl mt-8 hover:scale-105 transition duration-150 ease-in-out">
        <img src="../../assets/Images/Image2.jpg" alt="Image 2" class="w-full h-100 rounded-2xl mt-8 hover:scale-105 transition duration-150 ease-in-out">
        <img src="../../assets/Images/Image5.jpg" alt="Image 5" class="five w-full h-full rounded-2xl hover:scale-105 transition duration-150 ease-in-out">
        <img src="../../assets/Images/Image4.jpg" alt="Image 4" class="four w-full h-full rounded-2xl hover:scale-105 transition duration-150 ease-in-out">
        <img src="../../assets/Images/Image3.jpg" alt="Image 3" class="w-full h-auto rounded-2xl hover:scale-105 transition duration-150 ease-in-out">
        <img src="../../assets/Images/Image6.jpg" alt="Image 6" class="w-full h-auto rounded-2xl mb-6 hover:scale-105 transition duration-150 ease-in-out">
         </div>
    </div>
</div>
