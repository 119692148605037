import { Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'new-Website';
testimonialsData: any;

constructor(){
  console.log(environment.apiUrl);
  console.log(environment.Common);
  console.log(process.env['API_KEY']);
}
}
