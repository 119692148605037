import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MediaComponent } from './media.component';

@NgModule({
    declarations: [MediaComponent],
    imports: [
      CommonModule,
      RouterModule.forChild([
          {
            path: 'media',
            component: MediaComponent,
          },
        ]),
      ],
      schemas: [
        NO_ERRORS_SCHEMA
      ],
    })
export class MediaModule { }
